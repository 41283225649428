const store = {}

const formatKey = (key: string) => key.toLowerCase()

export default {
  set: <T = any>(key: string, value: T) => (store[formatKey(key)] = value),
  get: <T = any>(key: string) => store[formatKey(key)] as T,
  has: (key: string) => formatKey(key) in store,
  del: (key: string) => delete store[formatKey(key)]
}
